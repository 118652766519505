<template>
	<v-sheet class="customer" id="customer" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="6" class="my-auto">
				<h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
			</v-col>
			<v-col md="6" class="text-right">
				<v-btn
					v-if="stepper > 1"
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading"
					v-on:click="goBackStepper()"
				>
					Back
				</v-btn>
				<v-btn
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading"
					v-on:click="updateOrCreate()"
				>
					Save
					<template v-if="propertyCheckbox && stepper < 2">&amp; Next</template>
				</v-btn>
				<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
			</v-col>
			<v-col md="12">
				<v-stepper flat tile class="item-group-stepper" v-model="stepper">
					<v-stepper-header>
						<v-stepper-step class="py-2 btx-label" :complete="stepper > 1" step="1">
							Overview
						</v-stepper-step>

						<v-divider></v-divider>

						<v-stepper-step v-if="false" class="py-2 btx-label" :complete="stepper > 2" step="2">
							Contact Persons
						</v-stepper-step>

						<template v-if="propertyCheckbox">
							<v-divider></v-divider>

							<v-stepper-step class="py-2 btx-label" :complete="stepper > 2" step="2">
								Address
							</v-stepper-step>
						</template>
					</v-stepper-header>

					<v-stepper-items>
						<v-stepper-content class="pt-0" step="1">
							<perfect-scrollbar
								:options="{ suppressScrollX: true }"
								class="scroll custom-box-top-inner-shadow"
								style="max-height: calc(100vh - 185px); position: relative"
							>
								<v-form
									ref="overviewForm"
									v-model.trim="formValid"
									lazy-validation
									v-on:submit.stop.prevent="updateOrCreate()"
								>
									<v-row>
										<v-col md="8">
											<v-row>
												<v-col md="3" class="my-auto py-0">
													<label for="first-name" class="btx-label mt-2">Primary Contact</label>
												</v-col>
												<v-col md="9" class="py-0">
													<v-layout>
														<v-flex class="max-width-100px">
															<SelectInput
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																:items.sync="listTitle"
																id="salutation"
																placeholder="Title"
																v-model="customer.salutation"
																v-on:change="updateDefaultPerson()"
															></SelectInput>
														</v-flex>
														<v-flex class="mx-2">
															<TextInput
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																id="first-name"
																placeholder="First Name"
																v-model="customer.first_name"
																v-on:keyup="updateCustomerDisplayName()"
															></TextInput>
														</v-flex>
														<v-flex>
															<TextInput
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																id="last-name"
																placeholder="Last Name"
																v-model="customer.last_name"
																v-on:keyup="updateCustomerDisplayName()"
															></TextInput>
														</v-flex>
													</v-layout>
												</v-col>
												<v-col md="3" class="my-auto py-0">
													<label for="company-name" class="btx-label mt-2 required">Company Name</label>
												</v-col>
												<v-col md="9" class="py-0">
													<TextInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="company-name"
														placeholder="Company Name"
														:rules="[
															vrules.required(customer.company_name, 'Company Name'),
															companyNameError ? false : true,
														]"
														:class="{
															required: !customer.company_name,
														}"
														v-model="customer.company_name"
														v-on:change="validateCompanyName()"
														v-on:keyup="updateDefaultPerson()"
													></TextInput>
													<span v-if="companyNameError" class="red--text text--darken-1 font-small">{{
														companyNameError
													}}</span>
												</v-col>
												<v-col md="3" class="my-auto py-0">
													<label for="display-name" class="btx-label mt-2 required"
														>Display Name
														<TooltipQuestion>
															<template v-slot:text
																>This name will be displayed on<br />the transactions you create for<br />this
																customer</template
															>
														</TooltipQuestion>
													</label>
												</v-col>
												<v-col md="9" class="py-0">
													<TextInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="display-name"
														:rules="[vrules.required(customer.display_name, 'Display Name')]"
														:class="{
															required: !customer.display_name,
														}"
														placeholder="Display Name"
														v-model="customer.display_name"
														v-on:keyup="updateDefaultPerson()"
													></TextInput>
												</v-col>
												<v-col md="3" class="my-auto py-0">
													<label for="display-name" class="btx-label mt-2">UEN </label>
												</v-col>
												<v-col md="9" class="py-0">
													<TextInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="display-name"
														placeholder="UEN Number"
														v-model="customer.uen"
														v-on:keyup="updateDefaultPerson()"
													></TextInput>
												</v-col>
												<template v-if="!uuid">
													<v-col md="3" class="my-auto py-0">
														<label for="customer-email" class="btx-label mt-2 required">Email Address</label>
													</v-col>
													<v-col md="9" class="py-0">
														<EmailInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="customer-email"
															placeholder="Email Address"
															v-model="customer.email"
															:rules="[vrules.required(customer.email, 'Email Address')]"
															:class="{
																required: !customer.email,
															}"
															v-on:keyup="updateDefaultPerson()"
														></EmailInput>
													</v-col>
												</template>
												<v-col md="3" class="my-auto py-0">
													<label for="customer-phone-no" class="btx-label mt-2 required">Phone</label>
												</v-col>
												<v-col md="9" class="py-0">
													<PhoneTemplate
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="customer-phone-no"
														placeholder="Phone No."
														v-model="customer.phone_no"
														:rules="[vrules.required(customer.phone_no, 'Phone No')]"
														:class="{
															required: !customer.phone_no,
														}"
														v-on:keyup="updateDefaultPerson()"
													></PhoneTemplate>
												</v-col>
												<template v-if="!uuid">
													<v-col md="3" class="my-auto py-0">
														<label for="customer-incharge-officers" class="btx-label mt-2"
															>Incharge Officers</label
														>
													</v-col>
													<v-col md="9" class="py-0">
														<AutoCompleteInput
															multiple
															hide-details
															:items.sync="inchargeOfficerLists"
															item-text="text"
															item-value="value"
															id="customer-incharge-officers"
															placeholder="Incharge Officers"
															v-model="customer.incharge_officers"
															:disabled="pageLoading"
															:loading="pageLoading"
														></AutoCompleteInput>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="customer-designation" class="btx-label mt-2 required">Designation</label>
													</v-col>
													<v-col md="9" class="py-0">
														<SelectInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															append-outer-icon="mdi-cog"
															:items.sync="designationLists"
															placeholder="Designation"
															item-text="value"
															item-value="value"
															id="customer-designation"
															v-on:click:append-outer="manageDesignationDialog = true"
															v-model="customer.designation"
															:class="{
																required: !customer.designation,
															}"
															:rules="[vrules.required(customer.designation, 'Designation')]"
															v-on:change="updateDefaultPerson()"
														></SelectInput>
													</v-col>
												</template>
												<template v-if="false">
													<v-col md="3" class="my-auto py-0">
														<label for="customer-fax" class="btx-label mt-2">Fax</label>
													</v-col>
													<v-col md="9" class="py-0">
														<PhoneTemplate
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="customer-fax"
															placeholder="Fax"
															v-model="customer.fax"
														></PhoneTemplate>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="customer-website" class="btx-label mt-2">Website</label>
													</v-col>
													<v-col md="9" class="py-0">
														<TextInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="customer-website"
															placeholder="Website"
															v-model="customer.website"
														></TextInput>
													</v-col>
													<v-col md="3" class="py-0">
														<label for="customer-remark" class="btx-label mt-2"
															>Remarks<br /><span class="text-muted">(For Internal Use)</span></label
														>
													</v-col>
													<v-col md="9" class="py-0">
														<TextAreaInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															:rows="5"
															id="customer-remark"
															placeholder="Remarks (For Internal Use)"
															v-model="customer.remark"
														></TextAreaInput>
													</v-col>
													<v-col v-if="!uuid" md="9" class="py-0">
														<v-checkbox
															dense
															v-model="propertyCheckbox"
															hide-details
															class="m-0 p-0"
															label="Customer Has Property"
														></v-checkbox>
													</v-col>
												</template>
											</v-row>
										</v-col>
										<v-col md="4">
											<v-row>
												<v-col md="12" class="my-6 py-0 text-center">
													<Avatar can-change v-model="customer.image"></Avatar>
												</v-col>
											</v-row>
										</v-col>
									</v-row>
								</v-form>
							</perfect-scrollbar>
						</v-stepper-content>
						<v-stepper-content v-if="false" class="pt-0" step="2">
							<perfect-scrollbar
								:options="{ suppressScrollX: true }"
								class="scroll custom-box-top-inner-shadow"
								style="max-height: calc(100vh - 185px); position: relative"
							>
								<v-form
									ref="personForm"
									v-model.trim="formValid"
									lazy-validation
									v-on:submit.stop.prevent="updateOrCreate()"
								>
									<ContactPerson
										:detail="customer"
										v-on:saveContactPerson="updateContactPerson"
										:update-contact="updateCustomer || !!cloneUUID"
										:default-contact-persons="contact_person"
									>
									</ContactPerson>
								</v-form>
							</perfect-scrollbar>
						</v-stepper-content>
						<template v-if="propertyCheckbox">
							<v-stepper-content class="pt-0" step="2">
								<perfect-scrollbar
									:options="{ suppressScrollX: true }"
									class="scroll custom-box-top-inner-shadow"
									style="max-height: calc(100vh - 185px); position: relative"
								>
									<v-form
										ref="addressForm"
										v-model.trim="formValid"
										lazy-validation
										v-on:submit.stop.prevent="updateOrCreate()"
									>
										<v-row>
											<v-col md="8">
												<v-row class="px-4">
													<v-col v-if="false" md="4" class="my-auto py-0">
														<label :for="`address-name-${stringId}`" class="btx-label mt-2 required"
															>Contact Person</label
														>
													</v-col>
													<v-col v-if="false" md="8" class="py-0">
														<SelectInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															:items.sync="contact_person"
															id="contact_person"
															placeholder="Contact Person"
															item-text="display_name"
															item-value="email"
															v-model="address.contact_person"
															:class="{
																required: !address.contact_person,
															}"
															:rules="[vrules.required(address.contact_person, 'Contact Person')]"
														></SelectInput>
													</v-col>
													<template v-if="false">
														<v-col md="4" class="my-auto py-0">
															<label :for="`address-name-${stringId}`" class="btx-label mt-2 required">Name</label>
														</v-col>
														<v-col md="8" class="py-0">
															<TextInput
																hide-details
																:id="`address-name-${stringId}`"
																placeholder="Name"
																v-model="address.name"
																:rules="[vrules.required(address.name, 'Name')]"
																:class="{
																	required: !address.name,
																}"
																:disabled="pageLoading"
																:loading="pageLoading"
															></TextInput>
														</v-col>
													</template>
													<v-col md="4" class="my-auto py-0">
														<label :for="`address-postalcode-${stringId}`" class="btx-label mt-2 required"
															>Postal Code</label
														>
													</v-col>
													<v-col md="8" class="py-0">
														<PostalCodeTemplate
															hide-details
															:id="`address-postalcode-${stringId}`"
															placeholder="Postal Code"
															v-model="address.address_postal_code"
															:rules="[vrules.required(address.address_postal_code, 'Postal Code')]"
															:disabled="pageLoading"
															:loading="pageLoading"
															:class="{
																required: !address.address_postal_code,
															}"
														></PostalCodeTemplate>
													</v-col>
													<v-col md="4" class="mt-2 py-0">
														<label :for="`address-street-1-${stringId}`" class="btx-label mt-2 required"
															>Address</label
														>
													</v-col>
													<v-col md="8" class="py-0">
														<TextAreaInput
															hide-details
															:id="`address-street-1-${stringId}`"
															placeholder="Address"
															v-model="address.address_line_1"
															:disabled="pageLoading"
															:rules="[vrules.required(address.address_line_1, 'Address')]"
															:loading="pageLoading"
															:class="{
																required: !address.address_line_1,
															}"
														></TextAreaInput>
													</v-col>
													<template v-if="false">
														<v-col md="4" class="py-0"> </v-col>
														<v-col md="8" class="py-0">
															<TextAreaInput
																hide-details
																:id="`address-street-2-${stringId}`"
																placeholder="Street 2"
																v-model="address.address_line_2"
																:disabled="pageLoading"
																:loading="pageLoading"
																:class="{
																	required: !address.address_line_2,
																}"
															></TextAreaInput>
														</v-col>
													</template>
													<template v-if="false">
														<v-col md="4" class="my-auto py-0">
															<label :for="`address-country-${stringId}`" class="btx-label mt-2"
																>Country / Region</label
															>
														</v-col>
														<v-col md="8" class="py-0">
															<AutoCompleteInput
																hide-details
																:items.sync="countries"
																item-text="name"
																item-value="name"
																:id="`address-country-${stringId}`"
																placeholder="Country / Region"
																v-model="address.address_country"
																:disabled="pageLoading"
																:loading="pageLoading"
															></AutoCompleteInput>
														</v-col>
													</template>
												</v-row>
											</v-col>
										</v-row>
									</v-form>
								</perfect-scrollbar>
							</v-stepper-content>
						</template>
					</v-stepper-items>
				</v-stepper>
			</v-col>
		</v-row>
		<template v-if="manageDesignationDialog">
			<ManageDesignation
				:dialog="manageDesignationDialog"
				:designation="designationLists"
				v-on:close-dialog="manageDesignationDialog = false"
				v-on:get-designation="getDesignations"
			></ManageDesignation>
		</template>
	</v-sheet>
</template>
<script>
import { findIndex, toSafeInteger } from "lodash";
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { CreateOrUpdateCustomer, GetCustomer, ValidateCompanyName } from "@/core/lib/contact.lib";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import EmailInput from "@/view/components/EmailInput";
import SelectInput from "@/view/components/SelectInput";
import PhoneTemplate from "@/view/components/Phone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import Avatar from "@/view/components/Avatar";
import ManageDesignation from "@/view/module/contacts/Manage-Designation.vue";
import ApiService from "@/core/services/api.service";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import PostalCodeTemplate from "@/view/components/PostalCode";
import ContactPerson from "@/view/components/ContactPerson";
import ObjectPath from "object-path";

export default {
	name: "customer-create",
	title: "Create Customer",
	data() {
		return {
			uuid: null,
			cloneUUID: null,
			barcode: null,
			formValid: true,
			countries: [],
			designationLists: [],
			inchargeOfficerLists: [],
			manageDesignationDialog: false,
			pageLoading: false,
			propertyCheckbox: true,
			stepper: 1,
			updateCustomer: false,
			companyNameError: null,
			customer: {
				id: null,
				uuid: null,
				salutation: "mr",
				first_name: null,
				uen: null,
				last_name: null,
				company_name: null,
				image: null,
				designation: null,
				display_name: null,
				email: null,
				phone_no: null,
				fax: null,
				website: null,
				remark: null,
				incharge_officers: [],
			},
			address: {
				id: null,
				uuid: null,
				name: null,
				id_number: null,
				location: null,
				latitude: null,
				longitude: null,
				address_line_1: null,
				address_line_2: null,
				address_city: null,
				address_state: null,
				address_country: "Singapore",
				address_postal_code: null,
				description: null,
				address_type: 1,
				has_lat_long: 0,
			},
			contact_person: [
				{
					id: null,
					uuid: null,
					salutation: "mr",
					first_name: null,
					last_name: null,
					display_name: null,
					email: null,
					mobile: null,
					designation: null,
				},
			],
		};
	},
	components: {
		Avatar,
		TextInput,
		TextAreaInput,
		TooltipQuestion,
		AutoCompleteInput,
		PhoneTemplate,
		ContactPerson,
		PostalCodeTemplate,
		EmailInput,
		SelectInput,
		ManageDesignation,
	},
	methods: {
		validateCompanyName() {
			const _this = this;
			return new Promise((resolve, reject) => {
				_this.pageLoading = true;
				_this.companyNameError = null;
				ValidateCompanyName("customer/company-name", {
					"company-name": _this.customer.company_name,
					"customer-id": _this.customer.id,
				})
					.then((output) => {
						if (output.exists) {
							_this.companyNameError = `Company name ${_this.customer.company_name} already exists`;
							resolve(true);
						} else {
							resolve(false);
						}
					})
					.catch((error) => {
						reject(error);
					})
					.finally(() => {
						_this.pageLoading = false;
					});
			});
		},
		validateRadio(index) {
			let contactPersons = this.contact_person;
			this.contact_person = contactPersons.map((row) => {
				row.primary = false;
				return row;
			});
			this.contact_person[index].primary = true;
		},
		updateCustomerDisplayName() {
			const route_name = ObjectPath.get(this.$route, "name");

			if (route_name == "customer-update") {
				return false;
			}

			const customer = this.customer;
			if (customer) {
				const display_name = [];
				if (customer.first_name) {
					display_name.push(customer.first_name);
				}
				if (customer.last_name) {
					display_name.push(customer.last_name);
				}
				this.customer.display_name = display_name.join(" ");
				this.updateDefaultPerson();
			}
		},
		updatePersonDisplayName(index) {
			const route_name = ObjectPath.get(this.$route, "name");

			if (route_name == "customer-update") {
				return false;
			}

			const contact_person = this.contact_person[index];
			if (contact_person) {
				const display_name = [];
				if (contact_person.first_name) {
					display_name.push(contact_person.first_name);
				}
				if (contact_person.last_name) {
					display_name.push(contact_person.last_name);
				}
				this.contact_person[index].display_name = display_name.join(" ");
			}
		},
		getDesignations() {
			this.pageLoading = true;
			ApiService.setHeader();
			ApiService.get(`contact-options`)
				.then(({ data }) => {
					this.designationLists = data.designation;
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateDefaultPerson() {
			const route_name = ObjectPath.get(this.$route, "name");

			if (route_name == "customer-update") {
				return false;
			}

			const { salutation, first_name, display_name, last_name, email, phone_no, designation } =
				this.customer;

			let defaultIndex = toSafeInteger(findIndex(this.contact_person, { email }));

			if (defaultIndex < 0) {
				defaultIndex = 0;
			}

			const person = this.contact_person[defaultIndex];

			if (!person) {
				return false;
			}

			this.contact_person.splice(defaultIndex, 1, {
				id: person.id,
				uuid: person.uuid,
				salutation,
				first_name,
				last_name,
				email,
				display_name,
				mobile: phone_no,
				designation: designation,
			});
		},
		removePerson(index) {
			this.contact_person.splice(index, 1);
			if (this.contact_person.length <= 0) {
				this.addPerson();
			}
		},
		addPerson() {
			this.contact_person.push({
				id: null,
				uuid: null,
				salutation: "mr",
				first_name: null,
				last_name: null,
				email: null,
				full_name: null,
				default: false,
				work_phone: null,
				designation: null,
				mobile: null,
			});
		},
		async updateOrCreate() {
			const _this = this;

			const company_name_exists = await _this.validateCompanyName();

			if (company_name_exists) {
				_this.$store.commit(SET_ERROR, [
					{ model: true, message: `Company name ${_this.customer.company_name} already exists` },
				]);
				return false;
			}

			if (_this.stepper == 1) {
				const formErrors = _this.validateForm(_this.$refs.overviewForm);
				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}

				if (!_this.$refs.overviewForm.validate()) {
					return false;
				}
			}

			/*if (_this.stepper == 2) {
				if (_this.contact_person.length == 0) {
					_this.$store.commit(SET_ERROR, [
						{ model: true, message: "Please Add Atleast One Contact Person." },
					]);
					return false;
				}

				const formErrors = _this.validateForm(_this.$refs.personForm);
				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}

				if (!_this.$refs.personForm.validate()) {
					return false;
				}
			}*/

			if (_this.stepper == 2) {
				const formErrors = _this.validateForm(_this.$refs.addressForm);
				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}

				if (!_this.$refs.addressForm.validate()) {
					return false;
				}
			}

			let maxStepper = 2;
			if (!_this.propertyCheckbox) {
				maxStepper = 1;
			}

			if (_this.stepper < maxStepper) {
				_this.stepper++;
				return false;
			}

			try {
				_this.pageLoading = true;
				const formData = {
					contact: _this.customer,
					persons: _this.contact_person,
				};

				if (_this.propertyCheckbox) {
					_this.address.address_type = 2;
					formData.address = [_this.address];
				}

				const { uuid } = await CreateOrUpdateCustomer(formData);

				_this.$router.replace({
					name: "customer-detail",
					params: { uuid },
					query: { t: new Date().getTime() },
				});

				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Customer has been saved." },
				]);
			} catch (error) {
				_this.logError(error);
			} finally {
				_this.pageLoading = false;
			}
		},
		initCustomer({ contact, address, contact_person }) {
			const _this = this;

			_this.barcode = contact.barcode;

			_this.$store.dispatch(SET_BREADCRUMB, [
				{ text: "Contacts", disabled: true },
				{ text: "Customer", disabled: true },
				{ text: "Update", disabled: true },
				{ text: _this.barcode, disabled: true },
			]);

			_this.customer = {
				id: contact.id,
				uuid: contact.uuid,
				salutation: contact.title,
				first_name: contact.first_name,
				last_name: contact.last_name,
				company_name: contact.company_name,
				image: contact.image,
				display_name: contact.display_name,
				email: contact.email,
				phone_no: contact.phone,
				uen: contact.uen,
				fax: contact.fax,
				website: contact.website,
				remark: contact.remarks,
			};

			if (address && address[0]) {
				_this.address = {
					id: address[0].id,
					uuid: address[0].uuid,
					name: address[0].name,
					id_number: null,
					location: null,
					latitude: null,
					longitude: null,
					contact_person: address[0].contact_person ? address[0].contact_person.email : null,
					address_line_1: address[0].address_line_1,
					address_line_2: address[0].address_line_2,
					address_city: address[0].address_city,
					address_state: address[0].address_state,
					address_country: address[0].address_country,
					address_postal_code: address[0].address_postal_code,
					description: null,
					has_lat_long: 0,
				};
			}

			_this.contact_person = [];

			for (let i = 0; i < contact_person.length; i++) {
				_this.contact_person.push({
					id: contact_person[i].id,
					uuid: contact_person[i].uuid,
					salutation: contact_person[i].title,
					first_name: contact_person[i].first_name,
					last_name: contact_person[i].last_name,
					display_name: contact_person[i].display_name,
					designation: contact_person[i].designation,
					email: contact_person[i].email,
					mobile: contact_person[i].phone,
					primary: contact_person[i].primary == 1 ? true : false,
				});
			}

			if (!_this.contact_person.length) {
				_this.contact_person = [
					{
						id: null,
						uuid: null,
						salutation: "mr",
						first_name: null,
						last_name: null,
						display_name: null,
						email: null,
						mobile: null,
					},
				];
			}
		},
		getCustomer() {
			const _this = this;
			GetCustomer(_this.uuid)
				.then((data) => {
					_this.initCustomer(data);
				})
				.catch((error) => {
					_this.goBack();
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getCloneCustomer() {
			const _this = this;
			GetCustomer(_this.cloneUUID)
				.then((data) => {
					if (ObjectPath.has(data, "contact.id")) {
						ObjectPath.set(data, "contact.id", null);
						ObjectPath.set(data, "contact.uuid", null);
						if (ObjectPath.has(data, "contact.shipping_address.id")) {
							ObjectPath.set(data, "contact.shipping_address.id", null);
							ObjectPath.set(data, "contact.shipping_address.uuid", null);
						}
					}

					const contact_person = ObjectPath.get(data, "contact_person", []);
					for (let i = 0; i < contact_person.length; i++) {
						if (ObjectPath.has(data, `contact_person.${i}.id`)) {
							ObjectPath.set(data, `contact_person.${i}.id`, null);
							ObjectPath.set(data, `contact_person.${i}.uuid`, null);
							ObjectPath.set(data, `contact_person.${i}.contact`, null);
						}
					}

					const address = ObjectPath.get(data, "address", []);
					for (let z = 0; z < address.length; z++) {
						if (ObjectPath.has(data, `address.${z}.id`)) {
							ObjectPath.set(data, `address.${z}.id`, null);
							ObjectPath.set(data, `address.${z}.uuid`, null);
							ObjectPath.set(data, `address.${z}.contact`, null);
							if (ObjectPath.has(data, `address.${z}.contact_person.id`)) {
								ObjectPath.set(data, `address.${z}.contact_person.id`, null);
								ObjectPath.set(data, `address.${z}.contact_person.uuid`, null);
							}
						}
					}

					_this.$nextTick(() => {
						_this.initCustomer(data);
						_this.customer.designation = ObjectPath.get(data, "contact_person.0.designation", null);
					});
				})
				.catch((error) => {
					_this.goBack();
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getCountries() {
			const countries = localStorage.getItem("countries");
			if (countries) {
				this.countries = JSON.parse(countries);
			} else {
				this.countries = [
					{
						name: "Singapore",
						code: "SG",
						dial_code: "+65",
					},
				];
				localStorage.setItem("countries", JSON.stringify(this.countries));
			}
		},
		pageTitle() {
			if (this.uuid) {
				return this.customer.display_name + " - " + this.barcode;
			}
			return "Create new Customer";
		},
		updateContactPerson(data) {
			this.contact_person = data;
		},
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		stringId() {
			return this.stringUnique();
		},
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Contacts", disabled: true },
			{ text: "Customer", disabled: true },
			{ text: "Update", disabled: true },
		]);
		this.inchargeOfficerLists = this.localDB("members", []);
		this.getCountries();
		const { name, params } = this.$route;
		if (name === "customer-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			this.getCustomer();
		}
		if (this.uuid) {
			this.updateCustomer = true;
		}
		this.getDesignations();

		this.cloneUUID = ObjectPath.get(this.$route, "query.clone");

		if (this.cloneUUID) {
			this.getCloneCustomer();
		}
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
